<!--  -->
<template>
  <div id="wxMMLogin">
    <!-- <div class="logo">
      <img src="@/assets/images/logo.jpg" alt="" />
      <div>西知学院</div>
    </div>
    <div class="login">登陆后才能访问哦，快速登陆查看更多精彩内容吧！</div>
    <div class="btn">
      <van-button type="primary" @click="clickLogin()">微信登录</van-button>
      <van-button type="primary" @click="clickLoginPhone()">手机号登录</van-button>
    </div> -->
    <div class="logo">
      <img src="@/assets/images/favicon1.png" alt="" />
      <div>西语人的专属训练营</div>
    </div>
    <div class="btn">
      <div>
        <van-button type="primary" @click="clickLogin"
          ><i></i>微信登录</van-button
        >
      </div>
      <div>
        <van-button @click="clickLoginPhone">手机号登录</van-button>
      </div>
    </div>
    <div class="agreement">
      <!-- <van-radio-group v-model="checkBool">
       
          我以阅读并接受
          <a @click="agrenBtn">《西知网用户服务协议》、</a>
          <a>《隐私政策》</a>
      </van-radio-group> -->
      <van-checkbox v-model="checkBool" checked-color="#df3833">
        我以阅读并接受
        <a @click="agrenBtn">《西知网用户服务协议》、</a>
        <a>《隐私政策》</a></van-checkbox
      >
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      centerDialogVisible: true,
      checkBool: true, //是否同意
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    agrenBtn() {
      this.centerDialogVisible = true; //弹框显示
    },
    clickLogin() {
      let _this = this;
      _this.wxLogin("wx27c3900219360077", "http://m.tk.spanishknow.com/#/wxlo");
    },
    is_weixin() {
      let _this = this;
      // let ua = window.navigator.userAgent.toLowerCase();
      // if (ua.match(/MicroMessenger/i) == "micromessenger") {
      // } else {
      //   _this.$router.push("/");
      // }
    },
    clickLoginPhone() {
      let _this = this;
      _this.$router.push("/phoneLogin");
    },
    wxLogin(appid, url) {
      let redirect_uri = encodeURIComponent(url);
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATUS#wechat_redirect`;
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
@bgcolor: #df3833;
#wxMMLogin {
  width: 100%;
  padding: 0 40px;
  // .logo {
  //   padding: 50px;
  //   border-radius: 50%;
  //   display: flex;
  //   align-items: center;
  //   img {
  //     width: 80px;
  //     height: 80px;
  //     border-radius: 50%;
  //     margin-right: 40px;
  //   }
  //   div {
  //     font-size: 28px;
  //     width: 400px;
  //   }
  // }
  // .login {
  //   padding: 0 50px;
  //   font-weight: 700;
  //   font-size: 35px;
  // }
  // .btn {
  //   margin-top: 400px;
  //   margin-left: 225px;
  //   button {
  //     width: 300px;
  //     height: 80px;
  //     margin: 0 auto;
  //     background: #df3833;
  //     border: 1px solid #df3833;
  //     margin-bottom: 40px;
  //     &:nth-child(2) {
  //       background: #df3833;
  //       opacity: 0.5;
  //       border: 1px solid #df3833;
  //     }
  //   }
  // }
  .logo {
    text-align: center;
    margin: 80px 0;
    margin-bottom: 120px;
    img {
      width: 180px;
      height: 180px;
      margin-bottom: 40px;
    }
    div {
      font-size: 40px;
    }
  }
  .btn {
    margin-left: 15px;
    margin-bottom: 40px;
    button {
      width: 640px;
      border-radius: 80px;
      margin-bottom: 40px;
      height: 100px;
      background: @bgcolor;
      border: @bgcolor;
      i {
        margin-right: 20px;
        width: 60px;
        height: 60px;
        display: inline-block;
        margin-top: -6px;
        vertical-align: middle;
        background: url(../assets/images/weixin.svg);
        background-size: 60px 60px;
        background-repeat: no-repeat;
        background-position: 0 0;
      }
    }
    > div {
      &:nth-child(2) {
        button {
          color: #000;
          background: #fbe7e7;
          border: none;
        }
      }
    }
  }
  .agreement {
    font-size: 24px;
    // display: flex;
    // align-items: center;
    text-align: center;
    /deep/ .van-checkbox__label {
      margin-top: 5px;
    }
    a {
      color: @bgcolor;
    }
  }
}
</style>