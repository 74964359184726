<template>
  <div id="phoneLoginCode">
    <div class="top">填写动态码</div>
    <div class="bg"></div>
    <div class="duanxin">
      短信验证码已发送至 <span>{{ from.username }}</span>
    </div>
    <div class="phoneBox">
      <!-- 手机号 -->
      <div class="phone">
        <input v-model="from.research" placeholder="短信验证码" type="text" />
      </div>
      <van-button type="primary" @click="confirmBtn">提交</van-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      from: {
        username: "", //账号
        research: "", //验证码
        // password: "", //密码
      },
    };
  },
  methods: {
    clickLogin() {
      let _this = this;
      _this.$router.push("/");
    },
    clickSendWeixin() {
      let _this = this;
      _this.$router.push("/wxMMLogin");
    },
    clickSendCode() {
      let _this = this;
      if (_this.from.username == "") {
        this.$toast.fail("手机号不能空");
      } else if (_this.from.username.length < 11) {
        this.$toast.fail("手机号码有误，请重填");
      } else {
        let obj = {
          tel: _this.from.username,
        };
        _this.$api.easySms(obj).then((res) => {
          this.$toast.success("短信发送成功");
        });
      }
    },
    //验证
    confirmBtn() {
      //手机号登陆
      let _this = this;
      if (_this.from.research == "") {
        this.$toast.fail("验证码不能为空");
      } else {
        // _this.$store.commit("changeCode", _this.from.research);

        let obj = {
          tel: _this.from.username,
          username: _this.from.username,
          password: _this.from.username,
          code: _this.from.research,
        };
        _this.$api.resgiter(obj).then((res) => {
          // 提示信息
          localStorage.userInfo = JSON.stringify(res.data);
          // 1秒跳转
          _this.$api.check().then((res) => {
            if (res.data.state == 0) {
              this.$toast.success("登录成功");
              setTimeout(() => {
                // _this.$router.push(this.$store.state.locationHref);
                _this.$router.push("/Testsort");
                // window.location.href = this.$store.state.locationHref;
              }, 1000);
            } else {
              setTimeout(() => {
                _this.$router.push("/Avater");
              }, 1000);
            }
          });
        });
      }
    },
  },
  created() {
    let _this = this;
    _this.from.username = _this.$store.state.username;
  },
};
</script>

<style lang="less" scoped>
@bgcolor: #df3833;
#phoneLoginCode {
  width: 100%;
  padding: 40px 40px;
  .bg {
    width: 600px;
    height: 300px;
    background: url(../assets/images/code.svg) no-repeat 180px;
  }
  .duanxin {
    font-size: 30px;
    text-align: center;
    color: #999;
    span {
      color: @bgcolor;
    }
  }
  .top {
    font-size: 34px;
    color: #333;
    text-align: center;
    font-weight: 650;
    margin-bottom: 80px;
  }
  .phoneBox {
    text-align: center;
    .phone {
      border-radius: 80px;
      width: 640px;
      margin: 40px 0;
      height: 84px;
      border: 1px solid #ccc;
      display: flex;
      align-items: center;
      font-size: 28px;
      margin-left: 15px;
      input {
        font-size: 28px;
        box-sizing: border-box;
        // color: #333;
        margin: 40px 0;
        height: 100%;
        width: 100%;
        padding-left: 80px;
        border: none;
        background: url(../assets/images/codeD.png) no-repeat 20px 20px;
        background-size: 40px 40px;
      }
      //   input {
      //       border-radius: 40px;
      //       width: 300px;
      //       margin: 20px 0;
      //       height: 42px;
      //       border: 1px solid #ccc;
      //       display: flex;
      //       align-items: center;
      //       font-size: 14px;
      //       padding-left: 40px;
      //       margin-left: 15px;

      //     }
    }
    .weixin {
      cursor: pointer;
      font-size: 28px;
    }

    button {
      font-size: 30px;
      box-sizing: border-box;
      border-radius: 80px;
      width: 640px;
      border-radius: 80px;
      margin-bottom: 40px;
      height: 100px;
      background: @bgcolor;
      border: @bgcolor;
      // box-shadow: 0 2px 8px 0 rgba(47, 90, 255, 0.35);
    }
  }
}
</style>