<template>
  <div id="phoneLogin">
    <div class="top">注册/登录题库</div>
    <div class="phoneBox">
      <!-- 手机号 -->
      <div class="phone">
        <span>+86</span>
        <input v-model="from.username" placeholder="请输入手机号" type="text" />
      </div>
      <van-button type="primary" @click="clickSendCode">获取动态码</van-button>
      <!-- <div class="weixin" @click="clickSendWeixin">微信登录</div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      from: {
        username: "", //账号
        // research: "", //验证码
        // password: "", //密码
      },
    };
  },
  methods: {
    clickLogin() {
      let _this = this;
      _this.$router.push("/");
    },
    clickSendWeixin() {
      let _this = this;
      _this.$router.push("/wxMMLogin");
    },
    clickSendCode() {
      let _this = this;
      if (_this.from.username == "") {
        this.$toast.fail("手机号不能空");
      } else if (_this.from.username.length<11) {
        this.$toast.fail("手机号码有误，请重填");
      } else {
        let obj = {
          tel: _this.from.username,
        };
        _this.$api.easySms(obj).then((res) => {
          this.$toast.success("短信发送成功");
          _this.$store.commit("changeUsername", _this.from.username);

          setTimeout(() => {
            _this.$router.push("/phoneLoginCode");
          }, 1000);
        });
      }
    },
  },
  created() {
    let _this = this;
    // if (localStorage.userInfo) {
    //   _this.$router.push("/Testsort");
    // }
  },
};
</script>

<style lang="less" scoped>
@bgcolor: #df3833;
#phoneLogin {
  width: 100%;
  padding: 100px 40px;
  .top {
    font-size: 40px;
    color: #333;
    padding-left: 50px;
    font-weight: 700;
    margin-bottom: 80px;
  }
  .phoneBox {
    text-align: center;
    .phone {
      border-radius: 80px;
      width: 640px;
      margin: 40px 0;
      height: 84px;
      border: 1px solid #ccc;
      display: flex;
      align-items: center;
      font-size: 28px;
      padding-left: 36px;
      margin-left: 15px;
      input {
        font-size: 28px;
        box-sizing: border-box;
        color: #333;
        margin: 40px 0;
        height: 100%;
        padding-left: 30px;
        border: none;
      }
    }
    .weixin {
      cursor: pointer;
      font-size: 28px;
    }

    button {
      font-size: 30px;
      box-sizing: border-box;
      border-radius: 80px;
      width: 640px;
      border-radius: 80px;
      margin-bottom: 40px;
      height: 100px;
      background: @bgcolor;
      border: @bgcolor;
      // box-shadow: 0 2px 8px 0 rgba(47, 90, 255, 0.35);
    }
  }
}
</style>