const base = {
    APIlogin: '/login', //登录
    APIme: '/me', //登录

    APIeasySms: '/easySms', //验证码
    APIgetwxinfo: '/getwxinfophone', //微信登陆
    APIresgiter: '/resgiter', //注册
    APIclassesindexlist: '/question/classesindexlist', //首页jinqu
    APIclassQuestion: '/question/class', //首页分类
    APIrecords: '/question/class/records', //创建试卷
    APIclasses: '/question/classes', //首页

    APIshows: '/question/class/records/shows', //开始答题
    APIrecordtiemslist: '/question/recordtiemslist', //查看成绩
    APIsubmittest: "/question/class/records", //提交试卷
    APIwxLoginphone: "/wxLoginphone", //扫码登陆
    APIgetcountdown: "/getcountdown", //专八倒计时
    APIgetsign: "/getsign", //分享
    APIquestionclassshow: "/question/class/show", //试卷详情
    APIforgetpwd: "/forgetpwd", //找回密码
    APIdiscuss: "/question/comment", //获取评论
    APIdiscussCreate: "/question/comment", //创建评论
    APIsupport: "/question/comment/support", //创建赞/取消赞
    APIcheck: "/check", //检查是否是新用户
    APIavatarInfo: "/avatar/info", //获取上传授权信息（阿里云）【1】【头像】
    APIspanishknow: "http://img.spanishknow.com", //获取上传授权信息（阿里云）【2】【头像】
    APIEditme: "/me", //修改个人信息
    APIerrorlist: "/question/error/recordslist", //错题集
    APIemptyerrorstate: "/question/records/emptyerrorstate", //错题删除所有（个人）
    APIindex: "/index", //个人中心做题（错题）数量，时长
    APIWeeklyranking: "/question/rank", //用户排行
    APIcollectlist: "/question/collectlist", //收藏记录
    APIrecordsCollectlist: "/question/records/collection", //取消/收藏
    APIsortme: "/question/rank/me", //用户排行(自己的排名，需要登录)
    APIspecial: "/question/class/special", //专项练习
    APIforstudy: "/question/forstudy", //某某某用户 正在做 某某某试卷
    APIpractice: "/question/class/practice", //获取练习模式
    APIpracticeSave: "/question/class/practice/save", //保存练习模式
    // 配音秀
    APIarctype: "/arctype", //配音秀展示页

    APIpassworduser: "/inside/questions/classes/passworduser", //系统后台，查看试卷加密后做题用户
    APIpasswordusercheck: "/question/class/passworduser/check", //用户，查询加密试卷用户是否解密过
    APIpasswordcheck: "/question/class/password/check", //检查用户的密码是否正确
}
export default base;