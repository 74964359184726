import axios from 'axios';
import router from '../router'
import {
    Toast
} from 'vant';
// import qs from 'querystring';
export const service_url = "https://api.spanishknow.com/api"
var instance = axios.create({
    timeout: 60000
});
// axios的全局配置
instance.defaults.baseURL = service_url
instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
//设置axios请求头加入token
instance.interceptors.request.use(config => {
        if (config.push === '/') {} else {
            if (localStorage.getItem('userInfo')) {
                //在请求头加入token，名字要和后端接收请求头的token名字一样    
                config.headers.Authorization = 'Bearer ' + JSON.parse(localStorage.getItem('userInfo')).token;
            } else {

            }
        }

        return config;
    },
    error => {
        return Promise.reject(error);
    });



// 响应拦截器
instance.interceptors.response.use(
    response => {
        // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
        // 否则的话抛出错误 
        if (response.status === 200) {
            // // 这里可以根据code值进行判断处理，需要与后端协商统一
            // if (response.data.code == 0) {

            // } else if (response.data.code == 100) {
            //     Message({
            //         message: '网络请求不存在',
            //         duration: 1500,
            //         type: 'error'
            //     })

            // }
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    // 服务器状态码不是2开头的的情况
    // 然后根据返回的状态码进行一些操作，例如登录过期提示，错误提示等等
    // 下面列举几个常见的操作，其他需求可自行扩展
    error => {
        if (error.response.status) {

            switch (error.response.status) {
                case 401:
                    Toast.fail("登陆过期");

                    setTimeout(() => {
                        router.push("/phoneLogin")
                    }, 1000);

                    localStorage.removeItem("userInfo");

                    break;
                    // 其他错误，直接抛出错误提示
                case 500:
                    console.log(error.response.data.message);
                    if (error.response.data.message == "The token has been blacklisted") {
                        Toast.fail("登陆过期");

                        setTimeout(() => {
                            router.push("/phoneLogin")
                        }, 1000);

                        localStorage.removeItem("userInfo");
                    } else {
                        Toast.fail(error.response.data.message);
                    }
                    break;
                default:
                    Toast.fail(error.response.data.message);
            }
            return Promise.reject(error.response);

        }
    }
);

export default instance;