<template>
  <div id="Analysis">
    <van-overlay :show="loadingBool">
      <van-loading type="spinner" size="24px" color="#1989fa" vertical
        >加载中...</van-loading
      ></van-overlay
    >
    <div class="title">
      <div>
        <div>
          <i class="iconfont icon-wenjian"></i
          ><span>{{ defaultIndex + 1 }}/{{ total }}</span>
        </div>
        <div @click="clickReturn">
          <i class="iconfont icon-fanhui"></i>
          <span>返回</span>
        </div>
      </div>
      <p :style="{ width: widthActive + '%' }"></p>
    </div>

    <div class="main">
      <div
        v-if="v.isShowList"
        class="single"
        v-for="(v, i) in allList"
        :key="i"
      >
        <!-- 单选题 -->
        <div class="Multiple-Choice" v-if="v.question.type == 1 ? true : false">
          <div style="font-weight: 600">
            {{ i + 1 }}.<span v-if="v.question.defined == '' ? false : true">{{
              v.question.defined
            }}</span
            ><span v-else>{{ v.question.type == 1 ? "单选题" : "" }}</span
            ><span>({{ v.question.grade }}分)</span>
          </div>
          <div v-if="v.question.stem_type == 1 ? true : false">
            <div class="audioTitle">
              <div class="stem" v-html="v.question.stem"></div>
              <div
                class="single_choices"
                v-for="(item, index) in v.question.single_choices"
                :key="index"
              >
                <span :class="item.isShow ? 'active' : ''">{{
                  listSum[index]
                }}</span>
                <p v-html="item.answer"></p>
              </div>
            </div>
          </div>
          <!-- 音频 -->
          <div v-if="v.question.stem_type == 2 ? true : false">
            <div class="audioTitle">
              <div class="audioBox">
                <div class="btn">
                  <div class="back" @click="clickSnd(-15)">
                    <img src="../../assets/images/kuaijin1.png" alt="" />
                  </div>
                  <div>{{ v.question.file.filename }}</div>
                  <div class="forward" @click="clickSnd(15)">
                    <img src="../../assets/images/kuaijin2.png" alt="" />
                  </div>
                </div>
                <audio controls controlslist="nodownload">
                  <source :src="v.question.file.playpath" type="audio/mp3" />
                  您的浏览器不支持 HTML5 audio 标签。
                </audio>
              </div>
            </div>
            <div class="stem" v-html="v.question.stem"></div>

            <div
              class="single_choices"
              v-for="(item, index) in v.question.single_choices"
              :key="index"
            >
              <span :class="item.isShow ? 'active' : ''">{{
                listSum[index]
              }}</span>
              <p v-html="item.answer"></p>
            </div>
          </div>
          <!-- 视频 -->
          <div v-if="v.question.stem_type == 3 ? true : false">
            <video
              :src="v.question.file.playpath"
              controls="controls"
              preload="auto"
              poster=""
              x5-video-player-fullscreen="true"
              webkit-playsinline="true"
              x-webkit-airplay="true"
              playsinline="true"
              x5-video-player-type="h5"
            ></video>
            <div class="stem" v-html="v.question.stem"></div>

            <div
              class="single_choices"
              v-for="(item, index) in v.question.single_choices"
              :key="index"
            >
              <span :class="item.isShow ? 'active' : ''">{{
                listSum[index]
              }}</span>
              <p v-html="item.answer"></p>
            </div>
          </div>

          <div class="analysis">
            <!-- 答案 -->
            <div class="analysisDa">
              <div class="nide">
                <div>你的答案</div>
                <div class="youanswer">
                  {{ v.answers }}
                  <span v-if="v.answers == null ? true : false">未作答</span>

                  <i
                    class="iconfont icon-zhengque1"
                    style="color: #25c17c; font-size: 20px"
                    v-if="v.state == 1 ? true : false"
                  ></i
                  ><i
                    class="iconfont icon-cuowu"
                    style="color: #f4433c; font-size: 20px"
                    v-if="v.state == 0 ? true : false"
                  ></i>
                </div>
              </div>
              <div class="daan">
                <div class="firstdiv">正确答案</div>
                <div
                  class="single"
                  v-for="(item, index) in v.question.single_choices"
                  :key="index"
                  v-if="item.yes == 1 ? true : false"
                >
                  {{ listSum[index] }}
                </div>
              </div>
            </div>
            <div class="jiexi">
              <div>解析:</div>
              <div v-html="v.question.analysis"></div>
              <span v-if="v.question.analysis == '' ? true : false">暂无</span>
            </div>
          </div>
        </div>
        <!-- 多选题 -->
        <div class="MultiSelect" v-if="v.question.type == 2 ? true : false">
          <div style="font-weight: 600">
            {{ i + 1 }}.<span v-if="v.question.defined == '' ? false : true">{{
              v.question.defined
            }}</span
            ><span v-else>{{ v.question.type == 2 ? "多选题" : "" }}</span
            ><span>({{ v.question.grade }}分)</span>
          </div>
          <div v-if="v.question.stem_type == 1 ? true : false">
            <div class="audioTitle">
              <div class="stem" v-html="v.question.stem"></div>

              <div
                class="single_choices"
                v-for="(item, index) in v.question.multiple_choices"
                :key="index"
              >
                <span :class="item.isShow ? 'active' : ''">{{
                  listSum[index]
                }}</span>
                <p v-html="item.answer"></p>
              </div>
            </div>
          </div>
          <!-- 音频 -->
          <div v-if="v.question.stem_type == 2 ? true : false">
            <div class="audioTitle">
              <div class="audioBox">
                <div class="btn">
                  <div class="back" @click="clickSnd(-15)">
                    <img src="../../assets/images/kuaijin1.png" alt="" />
                  </div>
                  <div>{{ v.question.file.filename }}</div>
                  <div class="forward" @click="clickSnd(15)">
                    <img src="../../assets/images/kuaijin2.png" alt="" />
                  </div>
                </div>
                <audio controls controlslist="nodownload">
                  <source :src="v.question.file.playpath" type="audio/mp3" />
                  您的浏览器不支持 HTML5 audio 标签。
                </audio>
              </div>
            </div>
            <div class="stem" v-html="v.question.stem"></div>

            <div
              class="single_choices"
              v-for="(item, index) in v.question.multiple_choices"
              :key="index"
            >
              <span :class="item.isShow ? 'active' : ''">{{
                listSum[index]
              }}</span>
              <p v-html="item.answer"></p>
            </div>
          </div>
          <!-- 视频 -->
          <div v-if="v.question.stem_type == 3 ? true : false">
            <video
              :src="v.question.file.playpath"
              controls="controls"
              preload="auto"
              poster=""
              x5-video-player-fullscreen="true"
              webkit-playsinline="true"
              x-webkit-airplay="true"
              playsinline="true"
              x5-video-player-type="h5"
            ></video>
            <div class="stem" v-html="v.question.stem"></div>

            <div
              class="single_choices"
              v-for="(item, index) in v.question.multiple_choices"
              :key="index"
            >
              <span :class="item.isShow ? 'active' : ''">{{
                listSum[index]
              }}</span>
              <p v-html="item.answer"></p>
            </div>
          </div>

          <div class="analysis">
            <div class="analysisDa">
              <div class="nide">
                <div>你的答案:</div>
                <div class="youanswer">
                  {{ v.answers }}
                  <span v-if="v.answers == null ? true : false">未作答</span>
                  <i
                    class="iconfont icon-zhengque1"
                    style="color: #25c17c; font-size: 20px"
                    v-if="v.state == 1 ? true : false"
                  ></i
                  ><i
                    class="iconfont icon-cuowu"
                    style="color: #f4433c; font-size: 20px"
                    v-if="v.state == 0 ? true : false"
                  ></i>
                </div>
              </div>
              <div class="daad">
                <div class="firstdiv">正确答案</div>
                <span
                  class="single"
                  v-for="(item, index) in v.question.multiple_choices"
                  :key="index"
                  v-if="item.yes == 1 ? true : false"
                >
                  {{ listSum[index] }}
                </span>
              </div>
            </div>
            <div class="jiexi">
              <div>解析:</div>
              <div v-html="v.question.analysis"></div>
            </div>
          </div>
        </div>
        <!-- 判断题 -->
        <div class="judge" v-if="v.question.type == 3 ? true : false">
          <div style="font-weight: 600">
            {{ i + 1 }}.<span v-if="v.question.defined == '' ? false : true">{{
              v.question.defined
            }}</span
            ><span v-else>{{ v.question.type == 3 ? "判断题" : "" }}</span
            ><span>({{ v.question.grade }}分)</span>
          </div>
          <div v-if="v.question.stem_type == 1 ? true : false">
            <div class="audioTitle">
              <div class="stem" v-html="v.question.stem"></div>

              <div
                @click="clickJudges(i, index)"
                class="single_choices"
                v-for="(item, index) in v.question.judges"
                :key="index"
              >
                <span :class="item.isShow ? 'active' : ''"></span>
                <p v-html="item.answer"></p>
              </div>
            </div>
          </div>
          <!-- 音频 -->
          <div v-if="v.question.stem_type == 2 ? true : false">
            <div class="audioTitle">
              <div class="audioBox">
                <div class="btn">
                  <div class="back" @click="clickSnd(-15)">
                    <img src="../../assets/images/kuaijin1.png" alt="" />
                  </div>
                  <div>{{ v.question.file.filename }}</div>
                  <div class="forward" @click="clickSnd(15)">
                    <img src="../../assets/images/kuaijin2.png" alt="" />
                  </div>
                </div>
                <audio controls controlslist="nodownload">
                  <source :src="v.question.file.playpath" type="audio/mp3" />
                  您的浏览器不支持 HTML5 audio 标签。
                </audio>
              </div>
            </div>
            <div class="stem" v-html="v.question.stem"></div>

            <div
              @click="clickJudges(i, index)"
              class="single_choices"
              v-for="(item, index) in v.question.judges"
              :key="index"
            >
              <span :class="item.isShow ? 'active' : ''"></span>
              <p v-html="item.answer"></p>
            </div>
          </div>
          <!-- 视频 -->
          <div v-if="v.question.stem_type == 3 ? true : false">
            <video
              :src="v.question.file.playpath"
              controls="controls"
              preload="auto"
              poster=""
              x5-video-player-fullscreen="true"
              webkit-playsinline="true"
              x-webkit-airplay="true"
              playsinline="true"
              x5-video-player-type="h5"
            ></video>
            <div class="stem" v-html="v.question.stem"></div>

            <div
              @click="clickJudges(i, index)"
              class="single_choices"
              v-for="(item, index) in v.question.judges"
              :key="index"
            >
              <span :class="item.isShow ? 'active' : ''"></span>
              <p v-html="item.answer"></p>
            </div>
          </div>
          <div class="analysis">
            <div class="analysisDa">
              <div class="nide">
                <div>你的答案</div>
                <div class="youanswer">
                  {{ v.answers }}
                  <span v-if="v.answers == null ? true : false">未作答</span>
                  <i
                    class="iconfont icon-zhengque1"
                    style="color: #25c17c; font-size: 20px"
                    v-if="v.state == 1 ? true : false"
                  ></i
                  ><i
                    class="iconfont icon-cuowu"
                    style="color: #f4433c; font-size: 20px"
                    v-if="v.state == 0 ? true : false"
                  ></i>
                </div>
              </div>
              <div class="daan">
                <div>参考答案</div>
                <span
                  class="refrance"
                  v-for="(item, index) in v.question.judges"
                  :key="index"
                  v-if="item.yes == 1 ? true : false"
                >
                  {{ item.answer }}
                </span>
              </div>
            </div>
            <div class="jiexi">
              <div>解析:</div>
              <div v-html="v.question.analysis"></div>
            </div>
          </div>
        </div>
        <!-- 多元填空 -->
        <div class="multipleList" v-if="v.question.type == 6 ? true : false">
          <div style="font-weight: 600">
            {{ i + 1 }}.<span v-if="v.question.defined == '' ? false : true">{{
              v.question.defined
            }}</span
            ><span v-else>{{ v.question.type == 6 ? "多元填空题" : "" }}</span
            ><span>({{ v.question.grade }}分)</span>
          </div>
          <div v-if="v.question.stem_type == 1 ? true : false">
            <div class="audioTitle">
              <div
                class="stem"
                 :style="{ height: '100vh' - `${newHeight}px` }"
                v-html="v.question.stem"
              ></div>
            </div>

            <div id="box1" :style="{ height: newHeight + 'px' }">
              <div class="touchmove">
                <button
                  @touchstart="touchstart($event)"
                  @touchmove="touchmove($event)"
                >
                  按住拖动可调整页面
                </button>
              </div>
              <div
                id="box2"
                class="analysis"
                :style="{ height: newHeight + 'px' }"
              >
                <div class="daan">
                  <div
                    v-for="(item, index) in v.question.multiple_clozes"
                    :key="index"
                  >
                    <div>第({{ index + 1 }})空 ({{ item.grade }}分)</div>

                    <div class="youanswer">
                      你的答案:
                      <span>{{ v.answers[index] }}</span>
                      <span
                        v-if="
                          v.answers[index] == undefined ||
                          v.answers[index] == ''
                            ? true
                            : false
                        "
                        >未作答</span
                      >
                      <i
                        class="iconfont icon-zhengque1"
                        style="color: #25c17c; font-size: 20px"
                        v-if="item.succ"
                      ></i
                      ><i
                        class="iconfont icon-cuowu"
                        style="color: #f4433c; font-size: 20px"
                        v-if="!item.succ"
                      ></i>
                    </div>

                    <div class="reference1">
                      <span>参考答案:</span>
                     <div>
                        <div
                        v-for="(item1, index1) in item.answers"
                        :key="index1"
                      >
                        {{ item1.answer
                        }}<span v-if="index1 < item.answers.length - 1"></span>
                      </div>
                     </div>
                    </div>

                    <!-- </div> -->
                    <div class="resolve">
                      小题解析：{{ item.analysis
                      }}<span v-if="item.analysis == '' ? true : false"
                        >暂无</span
                      >
                    </div>
                  </div>
                </div>
                <!-- <div class="jiexi">
              <div>解析:</div>
              <div v-html="v.question.analysis"></div>
                <span v-if="v.question.analysis== '' ? true : false"

            </div> -->
              </div>
            </div>
          </div>
          <div v-if="v.question.stem_type == 2 ? true : false">
            <div class="audioTitle">
              <div class="audioBox">
                <div class="btn">
                  <div class="back" @click="clickSnd(-15)">
                    <img src="../../assets/images/kuaijin1.png" alt="" />
                  </div>
                  <div>{{ v.question.file.filename }}</div>
                  <div class="forward" @click="clickSnd(15)">
                    <img src="../../assets/images/kuaijin2.png" alt="" />
                  </div>
                </div>
                <audio controls controlslist="nodownload">
                  <source :src="v.question.file.playpath" type="audio/mp3" />
                  您的浏览器不支持 HTML5 audio 标签。
                </audio>
              </div>
              <div
                class="stem"
                 :style="{ height: '100vh' - `${newHeight}px` }"
                v-html="v.question.stem"
              ></div>
            </div>

            <div id="box1" :style="{ height: newHeight + 'px' }">
              <div class="touchmove">
                <button
                  @touchstart="touchstart($event)"
                  @touchmove="touchmove($event)"
                >
                  按住拖动可调整页面
                </button>
              </div>
              <div
                id="box2"
                class="analysis"
                :style="{ height: newHeight + 'px' }"
              >
                <div class="daan">
                  <div
                    v-for="(item, index) in v.question.multiple_clozes"
                    :key="index"
                  >
                    <div>第({{ index + 1 }})空 ({{ item.grade }}分)</div>

                    <div class="youanswer">
                      你的答案:
                      <span>{{ v.answers[index] }}</span>
                      <span
                        v-if="
                          v.answers[index] == undefined ||
                          v.answers[index] == ''
                            ? true
                            : false
                        "
                        >未作答</span
                      >
                      <i
                        class="iconfont icon-zhengque1"
                        style="color: #25c17c; font-size: 20px"
                        v-if="item.succ"
                      ></i
                      ><i
                        class="iconfont icon-cuowu"
                        style="color: #f4433c; font-size: 20px"
                        v-if="!item.succ"
                      ></i>
                    </div>

                   <div class="reference1">
                      <span>参考答案:</span>
                     <div>
                        <div
                        v-for="(item1, index1) in item.answers"
                        :key="index1"
                      >
                        {{ item1.answer
                        }}<span v-if="index1 < item.answers.length - 1"></span>
                      </div>
                     </div>
                    </div>

                    <!-- </div> -->
                    <div class="resolve">
                      小题解析：{{ item.analysis
                      }}<span v-if="item.analysis == '' ? true : false"
                        >暂无</span
                      >
                    </div>
                  </div>
                </div>
                <!-- <div class="jiexi">
              <div>解析:</div>
              <div v-html="v.question.analysis"></div>
                <span v-if="v.question.analysis== '' ? true : false"

            </div> -->
              </div>
            </div>
          </div>
          <div v-if="v.question.stem_type == 4 ? true : false">
            <div class="audioTitle">
              <img
               v-if="v.question.file"
                :src="v.question.file.playpath"
                alt=""
                @click="clickShowImagePreview(i)"
              />
              <div
                class="stem"
                 :style="{ height: '100vh' - `${newHeight}px` }"
                v-html="v.question.stem"
              ></div>
            </div>

            <div id="box1" :style="{ height: newHeight + 'px' }">
              <div class="touchmove">
                <button
                  @touchstart="touchstart($event)"
                  @touchmove="touchmove($event)"
                >
                  按住拖动可调整页面
                </button>
              </div>
              <div
                id="box2"
                class="analysis"
                :style="{ height: newHeight + 'px' }"
              >
                <div class="daan">
                  <div
                    v-for="(item, index) in v.question.multiple_clozes"
                    :key="index"
                  >
                    <div>第({{ index + 1 }})空 ({{ item.grade }}分)</div>

                    <div class="youanswer">
                      你的答案:
                      <span>{{ v.answers[index] }}</span>
                      <span
                        v-if="
                          v.answers[index] == undefined ||
                          v.answers[index] == ''
                            ? true
                            : false
                        "
                        >未作答</span
                      >
                      <i
                        class="iconfont icon-zhengque1"
                        style="color: #25c17c; font-size: 20px"
                        v-if="item.succ"
                      ></i
                      ><i
                        class="iconfont icon-cuowu"
                        style="color: #f4433c; font-size: 20px"
                        v-if="!item.succ"
                      ></i>
                    </div>

                    <div class="reference1">
                      <span>参考答案:</span>
                     <div>
                        <div
                        v-for="(item1, index1) in item.answers"
                        :key="index1"
                      >
                        {{ item1.answer
                        }}<span v-if="index1 < item.answers.length - 1"></span>
                      </div>
                     </div>
                    </div>

                    <!-- </div> -->
                    <div class="resolve">
                      小题解析：{{ item.analysis
                      }}<span v-if="item.analysis == '' ? true : false"
                        >暂无</span
                      >
                    </div>
                  </div>
                </div>
                <!-- <div class="jiexi">
              <div>解析:</div>
              <div v-html="v.question.analysis"></div>
                <span v-if="v.question.analysis== '' ? true : false"

            </div> -->
              </div>
            </div>
          </div>
        </div>

        <!-- 阅读题 -->
        <div class="reading" v-if="v.question.type == 9 ? true : false">
          <div style="font-weight: 600">
            {{ i + 1 }}.<span v-if="v.question.defined == '' ? false : true">{{
              v.question.defined
            }}</span
            ><span v-else>{{ v.question.type == 9 ? "阅读题" : "" }}</span
            ><span>({{ v.question.grade }}分)</span>
          </div>
          <div v-if="v.question.stem_type == 1 ? true : false">
            <div class="audioTitle">
              <div
                class="stem"
                 :style="{ height: '100vh' - `${newHeight}px` }"
                v-html="v.question.stem"
              ></div>

              <div id="box1" :style="{ height: newHeight + 'px' }">
                <div class="touchmove">
                  <button
                    @touchstart="touchstart($event)"
                    @touchmove="touchmove($event)"
                  >
                    按住拖动可调整页面
                  </button>
                </div>
                <div
                  id="box2"
                  class="analysis"
                  :style="{ height: newHeight + 'px' }"
                >
                  <div v-for="(v1, i1) in v.question.reads" :key="i1">
                    <div>
                      {{ i1 + 1 }}.{{
                        v1.question.stem == "null" ? "" : v1.question.stem
                      }}
                      <span>({{ v1.question.grade }}分)</span>
                    </div>

                    <div
                      class="single_choices"
                      v-for="(item, index) in v1.question.single_choices"
                      :key="index"
                    >
                      <span :class="item.isShow ? 'active' : ''">{{
                        listSum[index]
                      }}</span>
                      <p v-html="item.answer"></p>
                    </div>
                    <div class="analysis" style="margin-left: 20px">
                      <!-- 答案 -->

                      <div class="readingjiexi">
                        <div class="readingstyle">
                          <div class="nide">
                            <div style="color: black">你的答案</div>
                            <div class="youanswer">
                              {{ v1.answers }}
                              <span v-if="v1.answers == null ? true : false"
                                >未作答</span
                              >
                              <i
                                class="iconfont icon-zhengque1"
                                style="color: #25c17c; font-size: 20px"
                                v-if="v1.state == 1 ? true : false"
                              ></i
                              ><i
                                class="iconfont icon-cuowu"
                                style="color: #f4433c; font-size: 20px"
                                v-if="v1.state == 0 ? true : false"
                              ></i>
                            </div>
                          </div>
                          <div class="daan">
                            <div class="firstdiv" style="color: black">
                              正确答案
                            </div>
                            <div
                              class="reference"
                              v-for="(item, index) in v1.question
                                .single_choices"
                              :key="index"
                              v-if="item.yes == 1 ? true : false"
                            >
                              {{ listSum[index] }}
                            </div>
                          </div>
                        </div>

                        <div style="color: black; font-weight: 400">解析:</div>
                        <div v-html="v1.question.analysis"></div>
                        <span v-if="v1.question.analysis == '' ? true : false"
                          >暂无</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="jiexi">
                    <div>阅读解析:</div>
                    <div v-html="v.question.analysis"></div>
                    <span v-if="v.question.analysis == '' ? true : false"
                      >暂无</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 音频 -->
          <div class="reading" v-if="v.question.stem_type == 2 ? true : false">
            <div class="audioTitle">
              <div class="audioBox">
                <div class="btn">
                  <div class="back" @click="clickSnd(-15)">
                    <img src="../../assets/images/kuaijin1.png" alt="" />
                  </div>
                  <div>{{ v.question.file.filename }}</div>
                  <div class="forward" @click="clickSnd(15)">
                    <img src="../../assets/images/kuaijin2.png" alt="" />
                  </div>
                </div>
                <audio controls controlslist="nodownload">
                  <source :src="v.question.file.playpath" type="audio/mp3" />
                  您的浏览器不支持 HTML5 audio 标签。
                </audio>
              </div>
            </div>
            <div
              class="stem"
               :style="{ height: '100vh' - `${newHeight}px` }"
              v-html="v.question.stem"
            ></div>
            <div id="box1" :style="{ height: newHeight + 'px' }">
              <div class="touchmove">
                <button
                  @touchstart="touchstart($event)"
                  @touchmove="touchmove($event)"
                >
                  按住拖动可调整页面
                </button>
              </div>
              <div
                id="box2"
                class="analysis"
                :style="{ height: newHeight + 'px' }"
              >
                <div v-for="(v1, i1) in v.question.reads" :key="i1">
                  <div>
                    {{ i1 + 1 }}.{{
                      v1.question.stem == "null" ? "" : v1.question.stem
                    }}
                    <span>({{ v1.question.grade }}分)</span>
                  </div>

                  <div
                    class="single_choices"
                    v-for="(item, index) in v1.question.single_choices"
                    :key="index"
                  >
                    <span :class="item.isShow ? 'active' : ''">{{
                      listSum[index]
                    }}</span>
                    <p v-html="item.answer"></p>
                  </div>
                  <div class="analysis" style="margin-left: 20px">
                    <!-- 答案 -->

                    <div class="readingjiexi">
                      <div class="readingstyle">
                        <div class="nide">
                          <div style="color: black">你的答案</div>
                          <div class="youanswer">
                            {{ v1.answers }}
                            <span v-if="v1.answers == null ? true : false"
                              >未作答</span
                            >
                            <i
                              class="iconfont icon-zhengque1"
                              style="color: #25c17c; font-size: 20px"
                              v-if="v1.state == 1 ? true : false"
                            ></i
                            ><i
                              class="iconfont icon-cuowu"
                              style="color: #f4433c; font-size: 20px"
                              v-if="v1.state == 0 ? true : false"
                            ></i>
                          </div>
                        </div>
                        <div class="daan">
                          <div class="firstdiv" style="color: black">
                            正确答案
                          </div>
                          <div
                            class="reference"
                            v-for="(item, index) in v1.question.single_choices"
                            :key="index"
                            v-if="item.yes == 1 ? true : false"
                          >
                            {{ listSum[index] }}
                          </div>
                        </div>
                      </div>

                      <div>解析:</div>
                      <div v-html="v1.question.analysis"></div>
                      <span v-if="v1.question.analysis == '' ? true : false"
                        >暂无</span
                      >
                    </div>
                  </div>
                </div>
                <div class="jiexi">
                  <div>阅读解析:</div>
                  <div v-html="v.question.analysis"></div>
                  <span v-if="v.question.analysis == '' ? true : false"
                    >暂无</span
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- 视频 -->
          <div class="reading" v-if="v.question.stem_type == 3 ? true : false">
            <video
              :src="v.question.file.playpath"
              controls="controls"
              preload="auto"
              poster=""
              x5-video-player-fullscreen="true"
              webkit-playsinline="true"
              x-webkit-airplay="true"
              playsinline="true"
              x5-video-player-type="h5"
            ></video>
            <div
              class="stem"
              v-html="v.question.stem"
               :style="{ height: '100vh' - `${newHeight}px` }"
            ></div>
            <div id="box1" :style="{ height: newHeight + 'px' }">
              <div class="touchmove">
                <button
                  @touchstart="touchstart($event)"
                  @touchmove="touchmove($event)"
                >
                  按住拖动可调整页面
                </button>
              </div>
              <div
                id="box2"
                class="analysis"
                :style="{ height: newHeight + 'px' }"
              >
                <div v-for="(v1, i1) in v.question.reads" :key="i1">
                  <div>
                    {{ i1 + 1 }}.{{
                      v1.question.stem == "null" ? "" : v1.question.stem
                    }}
                    <span>({{ v1.question.grade }}分)</span>
                  </div>

                  <div
                    class="single_choices"
                    v-for="(item, index) in v1.question.single_choices"
                    :key="index"
                  >
                    <span :class="item.isShow ? 'active' : ''">{{
                      listSum[index]
                    }}</span>
                    <p v-html="item.answer"></p>
                  </div>
                  <div class="analysis">
                    <!-- 答案 -->

                    <div class="readingjiexi">
                      <div class="readingstyle">
                        <div class="nide">
                          <div style="color: black">你的答案</div>
                          <div class="youanswer">
                            {{ v1.answers }}
                            <span v-if="v1.answers == null ? true : false"
                              >未作答</span
                            >
                            <i
                              class="iconfont icon-zhengque1"
                              style="color: #25c17c; font-size: 20px"
                              v-if="v1.state == 1 ? true : false"
                            ></i
                            ><i
                              class="iconfont icon-cuowu"
                              style="color: #f4433c; font-size: 20px"
                              v-if="v1.state == 0 ? true : false"
                            ></i>
                          </div>
                        </div>
                        <div class="daan">
                          <div class="firstdiv" style="color: black">
                            正确答案
                          </div>
                          <div
                            class="reference"
                            v-for="(item, index) in v1.question.single_choices"
                            :key="index"
                            v-if="item.yes == 1 ? true : false"
                          >
                            {{ listSum[index] }}
                          </div>
                        </div>
                      </div>

                      <div>解析:</div>
                      <div v-html="v1.question.analysis"></div>
                      <span v-if="v1.question.analysis == '' ? true : false"
                        >暂无</span
                      >
                    </div>
                  </div>
                </div>
                <div class="jiexi">
                  <div>阅读解析:</div>
                  <div v-html="v.question.analysis"></div>
                  <span v-if="v.question.analysis == '' ? true : false"
                    >暂无</span
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- 图片 -->
          <div class="reading" v-if="v.question.stem_type == 4 ? true : false">
            <div class="audioTitle">
              <img
               v-if="v.question.file"
                :src="v.question.file.playpath"
                alt=""
                @click="clickShowImagePreview(i)"
              />
              <div
                class="stem"
                 :style="{ height: '100vh' - `${newHeight}px` }"
                v-html="v.question.stem"
              ></div>
            </div>

            <div id="box1" :style="{ height: newHeight + 'px' }">
              <div class="touchmove">
                <button
                  @touchstart="touchstart($event)"
                  @touchmove="touchmove($event)"
                >
                  按住拖动可调整页面
                </button>
              </div>
              <div
                id="box2"
                class="analysis"
                :style="{ height: newHeight + 'px' }"
              >
                <div v-for="(v1, i1) in v.question.reads" :key="i1">
                  <div>
                    {{ i1 + 1 }}.{{
                      v1.question.stem == "null" ? "" : v1.question.stem
                    }}
                    <span>({{ v1.question.grade }}分)</span>
                  </div>

                  <div
                    class="single_choices"
                    v-for="(item, index) in v1.question.single_choices"
                    :key="index"
                  >
                    <span :class="item.isShow ? 'active' : ''">{{
                      listSum[index]
                    }}</span>
                    <p v-html="item.answer"></p>
                  </div>
                  <div class="analysis" style="margin-left: 20px">
                    <!-- 答案 -->

                    <div class="readingjiexi">
                      <div class="readingstyle">
                        <div class="nide">
                          <div style="color: black">你的答案</div>
                          <div class="youanswer">
                            {{ v1.answers }}
                            <span v-if="v1.answers == null ? true : false"
                              >未作答</span
                            >
                            <i
                              class="iconfont icon-zhengque1"
                              style="color: #25c17c; font-size: 20px"
                              v-if="v1.state == 1 ? true : false"
                            ></i
                            ><i
                              class="iconfont icon-cuowu"
                              style="color: #f4433c; font-size: 20px"
                              v-if="v1.state == 0 ? true : false"
                            ></i>
                          </div>
                        </div>
                        <div class="daan">
                          <div class="firstdiv" style="color: black">
                            正确答案
                          </div>
                          <div
                            class="reference"
                            v-for="(item, index) in v1.question.single_choices"
                            :key="index"
                            v-if="item.yes == 1 ? true : false"
                          >
                            {{ listSum[index] }}
                          </div>
                        </div>
                      </div>

                      <div>解析:</div>
                      <div v-html="v1.question.analysis"></div>
                      <span v-if="v1.question.analysis == '' ? true : false"
                        >暂无</span
                      >
                    </div>
                  </div>
                </div>
                <div class="jiexi">
                  <div>阅读解析:</div>
                  <div v-html="v.question.analysis"></div>
                  <span v-if="v.question.analysis == '' ? true : false"
                    >暂无</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 问答题 -->
        <div class="Answer" v-if="v.question.type == 4 ? true : false">
          <div style="font-weight: 600">
            {{ i + 1 }}.<span v-if="v.question.defined == '' ? false : true">{{
              v.question.defined
            }}</span
            ><span v-else>{{ v.question.type == 4 ? "问答题" : "" }}</span
            ><span>({{ v.question.grade }}分)</span>
          </div>
          <div v-if="v.question.stem_type == 1 ? true : false">
            <div class="audioTitle"  :style="{ height: '100vh' - `${newHeight}px` }">
              <div class="stem" v-html="v.question.stem"></div>
            </div>
            <div id="box1" :style="{ height: newHeight + 'px' }">
              <div class="touchmove">
                <button
                  @touchstart="touchstart($event)"
                  @touchmove="touchmove($event)"
                >
                  按住拖动可调整页面
                </button>
              </div>

              <div
                id="box2"
                class="analysis"
                :style="{ height: newHeight + 'px' }"
              >
                <div class="nide-answer">
                  <div>你的答案</div>
                  <div class="youanswer">
                    <span class="fontcolor" style="word-break: break-all"
                      >{{ v.answers }}
                    </span>
                    <span v-if="v.answers == null ? true : false">未作答</span>
                  </div>

                  <div class="solution">解析:</div>
                  <div v-html="v.question.analysis"></div>
                  <span v-if="v.question.analysis == '' ? true : false"
                    >暂无</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div v-if="v.question.stem_type == 4 ? true : false">
            <div class="audioTitle"  :style="{ height: '100vh' - `${newHeight}px` }">
              <img
               v-if="v.question.file"
                :src="v.question.file.playpath"
                alt=""
                @click="clickShowImagePreview(i)"
              />
              <div class="stem" v-html="v.question.stem"></div>
            </div>

            <div id="box1" :style="{ height: newHeight + 'px' }">
              <div class="touchmove">
                <button
                  @touchstart="touchstart($event)"
                  @touchmove="touchmove($event)"
                >
                  按住拖动可调整页面
                </button>
              </div>
              <div
                id="box2"
                class="analysis"
                :style="{ height: newHeight + 'px' }"
              >
                <div class="nide-answer">
                  <div>你的答案</div>
                  <div class="youanswer">
                    <span class="fontcolor" style="word-break: break-all"
                      >{{ v.answers }}
                    </span>
                    <span v-if="v.answers == null ? true : false">未作答</span>
                  </div>

                  <div class="solution">解析:</div>
                  <div v-html="v.question.analysis"></div>
                  <span v-if="v.question.analysis == '' ? true : false"
                    >暂无</span
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- 时评 -->
          <div v-if="v.question.stem_type == 3 ? true : false">
            <div class="audioTitle"  :style="{ height: '100vh' - `${newHeight}px` }">
              <video
                :src="v.question.file.playpath"
                controls="controls"
                preload="auto"
                poster=""
                x5-video-player-fullscreen="true"
                webkit-playsinline="true"
                x-webkit-airplay="true"
                playsinline="true"
                x5-video-player-type="h5"
              ></video>
              <div class="stem" v-html="v.question.stem"></div>
            </div>

            <div id="box1" :style="{ height: newHeight + 'px' }">
              <div class="touchmove">
                <button
                  @touchstart="touchstart($event)"
                  @touchmove="touchmove($event)"
                >
                  按住拖动可调整页面
                </button>
              </div>
              <div
                id="box2"
                class="analysis"
                :style="{ height: newHeight + 'px' }"
              >
                <div class="nide-answer">
                  <div>你的答案</div>
                  <div class="youanswer">
                    <span class="fontcolor" style="word-break: break-all"
                      >{{ v.answers }}
                    </span>
                    <span v-if="v.answers == null ? true : false">未作答</span>
                  </div>

                  <div class="solution">解析:</div>
                  <div v-html="v.question.analysis"></div>
                  <span v-if="v.question.analysis == '' ? true : false"
                    >暂无</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 听力题 -->
        <div class="Hearing" v-if="v.question.type == 10 ? true : false">
          <div style="font-weight: 600; margin-left: 15px">
            {{ i + 1 }}.<span v-if="v.question.defined == '' ? false : true">{{
              v.question.defined
            }}</span
            ><span v-else>{{ v.question.type == 10 ? "听力题" : "" }}</span
            ><span>({{ v.question.grade }}分)</span>
          </div>
          <!-- 音频 -->
          <div v-if="v.question.stem_type == 2 ? true : false">
            <div class="audioTitle"  :style="{ height: '100vh' - `${newHeight}px` }">
              <div class="audioBox">
                <div class="btn">
                  <div class="back" @click="clickSnd(-15)">
                    <img src="../../assets/images/kuaijin1.png" alt="" />
                  </div>
                  <div>{{ v.question.file.filename }}</div>
                  <div class="forward" @click="clickSnd(15)">
                    <img src="../../assets/images/kuaijin2.png" alt="" />
                  </div>
                </div>
                <audio controls controlslist="nodownload">
                  <source :src="v.question.file.playpath" type="audio/mp3" />
                  您的浏览器不支持 HTML5 audio 标签。
                </audio>
              </div>
              <div class="stem" v-html="v.question.stem"></div>
            </div>
          </div>
          <div id="box1" :style="{ height: newHeight + 'px' }">
            <div class="touchmove">
              <button
                @touchstart="touchstart($event)"
                @touchmove="touchmove($event)"
              >
                按住拖动可调整页面
              </button>
            </div>

            <div
              id="box2"
              class="analysis"
              :style="{ height: newHeight + 'px' }"
            >
              <div class="nide-answer">
                <div>你的答案</div>
                <div class="youanswer">
                  <span class="fontcolor" style="word-break: break-all"
                    >{{ v.answers }}
                  </span>
                  <span v-if="v.answers == null ? true : false">未作答</span>
                </div>

                <div class="solution">解析:</div>
                <div v-html="v.question.analysis"></div>
                <span v-if="v.question.analysis == '' ? true : false"
                  >暂无</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <!-- <div @click="returnBtn">返回</div> -->
      <div @click="clickCard">
        <i class="iconfont icon-datiqia"></i> <span class="card">答题卡</span>
      </div>
      <div>
        <button
          class="confim"
          :class="upperBool ? 'active' : ''"
          :disabled="!upperBool"
          @click="clickShow(-1)"
        >
          上一题
        </button>
        <button
          class="confim"
          :class="downBool ? 'active' : ''"
          v-if="downBool"
          @click="clickShow(1)"
        >
          下一题
        </button>
        <button class="confim" v-if="!downBool" @click="clickReturn">
          <i class="iconfont icon-fanhui"></i>
          <span>返回</span>
        </button>
      </div>
    </div>
    <van-action-sheet
      v-if="show"
      v-model="show"
      title="答题卡"
      :style="{ height: '50%' }"
    >
      <!-- 答题卡 -->
      <div class="content">
        <h6>
          <span>答题概况</span>
        </h6>
        <div class="circle">
          <div
            :class="item.active ? 'active' : ''"
            v-for="(item, index) in allList"
            :key="index"
            @click="clickActiveTest(index)"
            :style="item.isShowList ? 'color:#25c17c;border-color:#25c17c' : ''"
          >
            {{ index + 1 }}
          </div>
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import $ from "jquery";
import { ImagePreview } from "vant";
import Aplayer from "vue-aplayer";
Aplayer.disableVersionBadge = true;
export default {
  head() {
    return {
      title: this.titlename,
    };
  },
  components: {
    Aplayer,
  },
  data() {
    return {
      loadingBool: true,
      total: 0, //总数
      singleData: [], //单选 1
      manyData: [], //多选 2
      judgeData: [], //判断题 3
      multivariateData: [], //多元填空题 6
      askData: [], //问答题 4
      hearingData: [], //听力题 10
      readingData: [], //阅读题 9
      question: {}, //创建的试卷
      radio: "",
      listSum: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      // listBool: ["正确","错误"],
      show: false, //答题卡
      showTop: false, //交卷
      date: "", //时间
      countyes: 0, //已答题
      countno: 0, //未答题
      circle: [], //答题卡
      allList: [], //所有数据
      upperBool: false, //上一题
      downBool: true, //下一题
      defaultIndex: 0, //激活题
      widthActive: "", //激活宽度
      papersShow: false, //交卷show
      multipleList: [], //多元填空input
      multipleListNum: [],
      allObj: {
        error_total: 0,
        total: 0,
      }, //所有数据
      redList: [], //阅读题小题
      newHeight: 300,
      images: [],
      titlename: "",
    };
  },
  methods: {
    clickSnd(time) {
      var audio = document.getElementsByTagName("audio");
      audio[0].currentTime += time;
      audio[0].play();
    },
    clickActiveTest(index) {
      // 点击答题卡激活对应题
      let _this = this;
      _this.defaultIndex = index;
      _this.allList.forEach((v, i) => {
        if (index == i) {
          v.isShowList = true;
        } else {
          v.isShowList = false;
        }
        if (
          _this.defaultIndex > 0 &&
          _this.defaultIndex < _this.allList.length - 1
        ) {
          _this.downBool = true; //激活下一题
          _this.upperBool = true; //激活上一题
        } else if (_this.defaultIndex == 0) {
          _this.downBool = true; //激活下一题
          _this.upperBool = false; //去掉上一题
        } else if (_this.defaultIndex == _this.allList.length - 1) {
          _this.downBool = false; //去掉下一题
          _this.upperBool = true; //激活上一题
        }
      });
    },
    clickCard() {
      // 点击答题卡
      let _this = this;
      _this.show = true;
      let num = 0;
      _this.allList.forEach((v) => {
        //已答和未答
        if (v.active) {
          ++num;
        }
      });

      _this.countyes = num;
    },
    clickShowImagePreview(index) {
      // 预览
      let _this = this;
      _this.images[0] = _this.allList[index].question.file.playpath;
      ImagePreview(_this.images);
    },
    touchstart(e) {
      e.preventDefault();
      this.init = e.targetTouches[0].clientY;
      this.parent = document.getElementById("box1");
      this.initHeight = this.parent.offsetHeight;
    },
    touchmove(e) {
      this.end = e.targetTouches[0].clientY;
      if (this.end > 120 && this.end < 500) {
        this.newHeight = -this.end + this.init + this.initHeight;
      }
    },
    clickReturn() {
      let _this = this;
      // _this.showTop = false;
      // window.history.go(-1);
      _this.$router.push("/Result")
    },

    clickShow(val) {
      //下一题上一题
      let _this = this;
      _this.newHeight = 300;
      $("#Analysis audio").each((i, v) => {
        v.pause();
      });
      $("#Analysis video").each((i, v) => {
        v.pause();
      });

      _this.defaultIndex += val;
      _this.allList.forEach((v, i) => {
        if (_this.defaultIndex == i) {
          v.isShowList = true;
        } else {
          v.isShowList = false;
        }
      });
      if (
        _this.defaultIndex > 0 &&
        _this.defaultIndex < _this.allList.length - 1
      ) {
        _this.downBool = true; //激活下一题
        _this.upperBool = true; //激活上一题
      } else if (_this.defaultIndex == 0) {
        _this.downBool = true; //激活下一题
        _this.upperBool = false; //去掉上一题
      } else if (_this.defaultIndex == _this.allList.length - 1) {
        _this.downBool = false; //去掉下一题
        _this.upperBool = true; //激活上一题
      }
    },

    records() {
      // 创建试卷
      let _this = this;
      this.loadingBool = true;
      //获取所有数据
      _this.$api.shows(_this.question).then((res) => {
        _this.allObj = res.data.question_class_record;

        res.data.question_class_record.items.forEach((v, i) => {
          v.isShowList = false; //题显示隐藏
          if (v.question.type == 1) {
            if (v.question_id_son == null) {
              v.isShowList = false; //题显示隐藏
              v.question.single_choices.forEach((v1, i1) => {
                v1.isShow = false;
                if (v.answers == _this.listSum[i1]) {
                  //做的题
                  v.question.single_choices[i1].isShow = true;
                }
              });
              // _this.singleData.push(v); //单选
            } else {
              _this.redList.push(v);
            }
          } else if (v.question.type == 2) {
            v.question.multiple_choices.forEach((v1, i1) => {
              v1.isShow = false;
              if (v.answers) {
                v.answers.split("").forEach((item, index) => {
                  if (item == _this.listSum[i1]) {
                    //做的题
                    v.question.multiple_choices[i1].isShow = true;
                  }
                });
              }
            });

            // _this.manyData.push(v); //多选
          } else if (v.question.type == 3) {
            v.question.judges.forEach((v1, i1) => {
              v1.isShow = false;
              if (v.answers == v1.answer) {
                //做的题
                v.question.judges[i1].isShow = true;
              }
            });
            // _this.judgeData.push(v); //判断题
          } else if (v.question.type == 4) {
            v.modelValue = "";
            _this.askData.push(v); //问答题
          } else if (v.question.type == 6) {
            v.question.stem = v.question.stem.replace(
              /____/g,
              "<input placeholder='点击答题'  />"
            );

            var n = v.question.stem.split("input").length - 1;
            _this.multipleListNum.push(n);
            // _this.multivariateData.push(v); //多元填空题
            if (v.answers) {
              v.answers = v.answers.substring(0, v.answers.length - 1); //截取最后一个+
              v.answers = v.answers.split("+"); //转成数组

              v.question.multiple_clozes.forEach((v1) => {
                v1.anss = []; //答案
                v1.answers.forEach((item) => {
                  v1.anss.push(item.answer);
                });
              });
              // item.anss.includes(v.answers[index])

              v.question.multiple_clozes.forEach((item, index) => {
                if (v.answers[index] != undefined) {
                  if (item.anss.includes(v.answers[index])) {
                    item.succ = true;
                  } else {
                    item.succ = false;
                  }
                }
              });
            } else {
              v.answers = [];
            }
          } else if (v.question.type == 9) {
            // v.question.reads.forEach((v1) => {
            //   v1.active = false; //阅读题小题是否做
            //   v1.question.single_choices.forEach((v2, i2) => {
            //     v2.isShow = false;
            //   });
            // });
            // _this.readingData.push(v); //阅读题
          } else if (v.question.type == 10) {
            v.modelValue = "";
            // _this.hearingData.push(v); //听力题
          }
          if (v.question_id_son == null) {
            _this.allList.push(v);
          }
        });
        // 按照question_id_son分类
        let b = _this.redList.reduce(
          (r, x) => (
            (r[x.question_id_son] || (r[x.question_id_son] = [])).push(x), r
          ),
          {}
        );
        let c = Object.keys(b).map((x) => b[x]);
        //吧单选中的阅读题替换阅读题
        c.forEach((v, i) => {
          v.forEach((item, index) => {
            // item.question.single_choices.forEach((v2) => {
            //   v2.isShow = false;
            // });
            _this.allList.forEach((v1, i1) => {
              if (v1.question.type == 9) {
                if (_this.allList[i1].question_id == item.question_id_son) {
                  v1.question.reads = v;
                }
              }
            });
            // // if (_this.readingData[i]) {

            //     _this.readingData[i].question.reads = v;
            //   }
            // // }
          });
        });

        // _this.allList = [
        //   ..._this.singleData,
        //   ..._this.manyData,
        //   ..._this.judgeData,
        //   ..._this.multivariateData,
        //   ..._this.askData,
        //   ..._this.hearingData,
        //   ..._this.readingData,
        // ];
        _this.allList[0].isShowList = true;
        _this.allList.forEach((v) => {
          v.active = false; //是否做题
        });
        _this.allList.forEach((v) => {
          if (v.question.type == 9) {
            v.question.reads.forEach((v1, i1) => {
              v1.active = false; //阅读题小题是否做
              v1.question.single_choices.forEach((v2, i2) => {
                v2.isShow = false;
                if (v1.answers == _this.listSum[i2]) {
                  //做的题
                  v2.isShow = true;
                }
              });
            });
          }
        });
        _this.total = _this.allList.length;
        _this.countno = _this.allList.length; //未作体术
        this.loadingBool = false;
      });
    },
    returnBtn() {
      let _this = this;
      _this.$router.push("./Result");
    },
  },
  updated() {
    this.$nextTick(() => {
      $(".jiexi").on("click", ".thumbnail-i", function () {
        ImagePreview([$(this).attr("src")]);
      });
    });
     this.$nextTick(() => {
      $(".audioTitle").on("click", ".thumbnail-i", function () {
        ImagePreview([$(this).attr("src")]);
      });
    });
    this.$nextTick(() => {
      $(".main").on("click", ".thumbnail-i", function () {
        ImagePreview([$(this).attr("src")]);
      });
    });
    
  },
  created() {
    let _this = this;
    _this.$store.commit("changelocationHref", window.location.href);
    // _this.$store.commit("changelocationHref", window.location.hash.substring(1, window.location.hash.length));
    _this.question = _this.$store.state.question;
    this.titlename = this.question.name + " - 西知题库";
    _this.records();
  },
  watch: {
    total() {
      let _this = this;
      _this.widthActive = ((_this.defaultIndex + 1) / _this.total) * 100;
    },
    defaultIndex() {
      let _this = this;
      _this.widthActive = ((_this.defaultIndex + 1) / _this.total) * 100;
    },
    allList: {
      handler() {
        if (this.defaultIndex == this.allList.length - 1) {
          this.downBool = false;
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
#Analysis {
    /deep/table {
    width: 700px !important;
    overflow-x:auto ;
    td {
      border-bottom: 1px solid #000 !important;

      border-right: 1px solid #000 !important;
      border-top:none !important;
      border-left:none !important;
    }

    border-top: 1px solid #000 !important;

    border-left: 1px solid #000 !important;

    border-spacing: 0 !important; /*去掉单元格间隙*/
  }
  /deep/.audioBox {
    margin: 0 30px;
    position: relative;
    padding-top: 100px;
    box-shadow: 0px 0px 10px #cfcfcf;
    // padding-left: 20px;
    margin-bottom: 20px;
    .btn {
      background: #fff;
      position: absolute;
      z-index: 100;
      display: flex;
      padding: 10px 130px !important;
      padding-bottom: 0 !important;
      padding-left: 100px !important;

      top: 20px;
      width: 100%;
      img {
        width: 60px !important;
        height: 60px !important;
        padding: 0;
      }
      div {
        &:nth-child(2) {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 430px;
          margin: 0 20px;
        }
      }
      // .forward {
      //   // margin-left: 300px;
      // }
    }
    audio {
      width: 94%;
      height: 110px;
      // outline: none;
      // display: flex;
      // background:#fff !important;
      // filter: invert(180,90,360); //c3 filter(滤镜) 属性
      // color: red;
      margin-left: 20px;
      border-radius: 10px;
    }

    audio::-webkit-media-controls-enclosure {
      // background: rgba(255, 255, 255, 1); //白色底
      /* 滤镜反转为 rgba(0, 162, 255, 1);rgba(255, 93, 0, 0.8)  #7eb637   10,10,10  #757575 */
      border-radius: 10px;
    }
    .media-controls-container,
    .media-controls-container {
      background: #7eb637;
      //滤镜反转为 rgba(0, 162, 255, 1);rgba(255, 93, 0, 0.8)  #7eb637
      border-radius: 10px;
      // color: red;
    }
    audio::-webkit-media-controls-play-button {
      height: 44px;
      width: 44px;
      min-width: 44px;
      border-radius: 50%;
      flex-basis: 44px;
      // border-color: red;
      //  background: red;
      // background: #000;
      // color: #4280f4;
      // color: #25c17c;
    }
    // source{
    //   background: red;
    // }
    // 音量隐藏
    audio::-webkit-media-controls-volume-control-container {
      display: none !important;
      // color: red;
      // background: red;
    }

    audio::-webkit-media-controls-current-time-display {
      order: 1; //设置弹性盒对象元素的顺序
      color: #000;
      text-shadow: unset;
    }

    audio::-webkit-media-controls-time-remaining-display {
      order: 2;
      color: #000;
      text-shadow: unset;
    }
  }
  /deep/video {
    z-index: 1;
  }
  /deep/#box1 {
    z-index: 100;
  }
  .van-loading {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .Multiple-Choice,
  .MultiSelect,
  .judge,
  .multipleList,
  .reading,
  .Answer,
  .Hearing {
    > div {
      &:first-child {
        font-weight: 600;
      }
    }
  }
  background: #fff;

  width: 100%;
  min-height: 100%;
  padding-top: 120px;
  padding-bottom: 120px;

  .title {
    position: fixed;
    top: 0;
    z-index: 100;
    height: 100px;
    line-height: 100px;
    width: 100%;
    background: #fff;
    box-shadow: 0 0px 10px #cfcfcf;
    color: #666;
    font-size: 24px;
    p {
      border: 2px solid #4280f4;
      height: 2px;
      position: absolute;
      top: 98px;
    }
    > div {
      padding: 0 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      > div {
        &:nth-child(2) {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
    .iconfont {
      font-size: 32px;
      margin-right: 5px;
    }
    .icon-zhengque {
      color: #25c17c;
    }
    .icon-cuowu {
      color: #f44336;
      font-size: 42px;
    }
    > div {
      display: flex;
      align-items: center;
      &:nth-child(2) {
        span {
          margin-right: 20px;
        }
      }
    }
  }
  .main {
    .audioTitle {
      margin-top: 40px;
    }
    .analysis {
      .analysisDa {
        .nide {
          .youanswer {
            display: flex;
            align-items: center;
          }
        }
      }
    }
    video {
      margin-top: 40px;
    }
    /deep/input {
      width: 150px;
      border: none;
      border-bottom: 1px solid #000;
      font-size: 24px;
      background: #fff;
      text-align: center;
      &::placeholder {
        font-size: 24px;
      }
    }
    .single_choices {
      display: flex;
      margin-bottom: 20px;
      margin-top: 20px;
      span {
        display: block;
        width: 50px;
        height: 50px;
        text-align: center;
        border-radius: 50%;
        line-height: 50px;
        background: #eee;
        &.active {
          background: #4280f4;
          color: #fff;
        }
      }
      div {
        width: 480px;
      }
    }

    textarea {
      width: 686px;
      border: 1px solid #ccc;
      text-align: left;
      resize: none;
      padding: 20px 15px 0px 20px;
      border-radius: 10px;
    }

    > div {
      font-size: 30px;
      > div {
        line-height: 50px;
      }

      .stem {
        &:nth-child(2) {
          margin-left: 20px;
        }
      }
      video {
        width: 100%;
        padding: 0 30px;
      }
      audio {
        margin: 0 30px;
      }
      textarea {
        margin: 0 30px;
      }
      .single_choices {
        span {
          margin-right: 20px;
        }
      }
    }
    .reading {
      .stem {
        height: 600px;
        overflow-y: scroll;
        // overflow-y:hidden;
        padding: 0 30px;
        background: #fff;
        padding-bottom: 207px !important;
      }

      #box2 {
        padding: 0 30px;
        padding-top: 10px !important;
        padding-bottom: 200px !important;
        .bottommsg {
          text-align: center;
          margin: 20px 0;
          color: #666;
          font-size: 28px;
        }
        > div {
          margin-top: 40px;
          background: #fff;
          box-shadow: 0px 0px 10px #cfcfcf;
          padding: 40px;
          border-radius: 10px;
          .analysis {
            padding: 0px;
            .readingjiexi {
              // padding: 20px;
              .readingstyle {
                margin-bottom: 30px;
                display: flex;
                justify-content: space-between;
                .youanswer {
                  color: #4280f4;
                  display: flex;
                  align-items: center;
                }

                .daan {
                  .reference {
                    color: #25c17c;
                  }
                }
              }
            }
          }
        }
        .jiexi {
          box-shadow: 0px 0px 10px #cfcfcf;

          border-radius: 10px;
          div {
            color: #000;
          }
        }
      }
    }
    .single_choices {
      padding-left: 30px;
      p {
        width: 500px;
      }
    }
    .single {
      > div {
        > div {
          &:nth-child(1) {
            padding: 0 30px;
          }
        }
      }
      .stem {
        padding: 0 30px;
      }
      .choce {
        padding: 0 30px;
      }
      /deep/p {
        // width: 700px;
        word-break: break-all;
      }
    }
    #box2 {
      // margin-top: 50px;
      padding: 0px 30px 20px 30px;
      .analysisDa {
        display: flex;
        justify-content: space-between;
        background: #fff;
        box-shadow: 2px 2px 10px #e9e9e9;
        padding: 40px;
        border-radius: 10px;
      }

      .nide {
        > div {
          &:nth-child(1) {
            color: #000;
          }
          .youanswer {
            color: #f44336;
            font-size: 36px;
            font-weight: 400;
            display: flex;
            align-items: center;
          }
        }
      }
      .daan {
        .firstdiv {
          &:nth-child(1) {
            color: #000;
          }
          .single {
            color: #25c17c;
            font-size: 36px;
            font-weight: 400;
          }
        }
      }
      .jiexi {
        border-radius: 10px;
        margin-top: 20px;
        background: #fff;
        box-shadow: 2px 2px 10px #e9e9e9;
        padding: 40px;
        margin-bottom: 40px;
        > div {
          &:nth-child(1) {
            color: #000;

            font-size: 30px;
            line-height: 20px;
          }
        }
      }
    }

    .analysis {
      // margin-top: 50px;
      padding: 0px 30px 20px 30px;
      .analysisDa {
        display: flex;
        justify-content: space-between;
        background: #fff;
        box-shadow: 2px 2px 10px #e9e9e9;
        padding: 40px;
        border-radius: 10px;
      }

      .nide {
        > div {
          &:nth-child(1) {
            color: #000;
          }
          .youanswer {
            color: #f44336;
            font-size: 36px;
            font-weight: 400;
            display: flex;
            align-items: center;
          }
        }
      }
      .daan {
        .firstdiv {
          &:nth-child(1) {
            color: #000;
          }
          .single {
            color: #25c17c;
            font-size: 36px;
            font-weight: 400;
          }
        }
      }
      .jiexi {
        border-radius: 10px;
        margin-top: 20px;
        background: #fff;
        box-shadow: 2px 2px 10px #e9e9e9;
        padding: 40px;
        margin-bottom: 40px;
        > div {
          &:nth-child(1) {
            color: #000;

            font-size: 30px;
            line-height: 20px;
          }
        }
      }
    }
    .jiexi {
      > div {
        &:nth-child(1) {
          color: red;
        }
      }
    }
  }
  .bottom {
    position: fixed;
    bottom: 0;
    z-index: 100;
    height: 100px;
    line-height: 100px;
    width: 100%;
    background: #fff;
    box-shadow: 2px 2px 10px #bebebe;
    padding: 0 30px;
    color: #666;
    font-size: 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .card {
      color: #4280f4;
    }
    i {
      font-size: 28px;
    }
    div {
      .confim {
        width: 150px;
        line-height: 70px;
        background: #fff;
        border: none;
        color: #ccc;
        &.active {
          color: #4280f4;
        }
      }
    }
  }
  .van-action-sheet,
  .van-popup {
    .content {
      h6 {
        padding: 20px 30px;
        font-size: 28px;
        border-bottom: 1px solid #ccc;
        span {
          font-weight: 400;
          margin-right: 20px;
        }
      }
      .circle {
        width: 100%;
        padding: 30px 45px;
        display: flex;
        flex-wrap: wrap;
        max-height: 460px;
        overflow: auto;
        div {
          text-align: center;
          line-height: 100px;
          color: #666;
          font-size: 28px;
          width: 100px;
          height: 100px;
          border: 1px solid #666;
          border-radius: 50%;
          margin-right: 38px;
          margin-bottom: 38px;
          &:nth-child(5n + 5) {
            margin-right: 0;
          }
          &.active {
            background: #1caf6a;
            border: none;
            color: #fff;
          }
        }
      }
    }
  }
  .showTop {
    .circle {
      max-height: 1100px !important;
    }
  }

  .van-overlay {
    z-index: 1000;
  }
  .van-popup {
    height: 100%;
    // .van-overlay{
    //   background: none;
    // }
    .j-juan {
      width: 100%;
      display: flex;
      position: fixed;
      bottom: 0;
      text-align: center;
      font-size: 24px;
      div {
        &:first-child {
          flex: 1.5;
          border-top: 1px solid #ccc;
          color: #666;
          padding: 10px 0;
          background: #fff;
          i {
            font-size: 36px;
            color: #ccc;
          }
        }
        &:last-child {
          flex: 4.5;
          background: #4280f4;
          padding: 10px 0;
          p {
            color: #fff;
            line-height: 94px;
          }
        }
      }
    }
  }
  .papersShow {
    .wrapper {
      padding: 400px 120px;
      div {
        width: 500px;
        height: 280px;

        &:nth-child(1) {
          background: url("../../assets/images/dialog-bg-3.png") no-repeat
            rgba(247, 49, 82, 1);
          background-size: 500px 260px;
        }
        &:nth-child(2) {
          background: #fff;
          line-height: 280px;
          text-align: center;
          font-size: 35px;
          font-weight: 700;
          border-bottom: 1px solid #ccc;
        }
        &:nth-child(3) {
          height: 100px;
          background: #fff;
          line-height: 100px;
          text-align: center;
          font-size: 30px;
          color: #1989fa;
        }
      }
    }
  }

  // 单选+多选
  .Multiple-Choice,
  .MultiSelect {
    .analysis {
      .analysisDa {
        .youanswer {
          color: #4280f4;
        }
        .single {
          color: #25c17c;
        }
      }
    }
  }
  // 多元填空
  .multipleList {
    .stem {
      height: 600px;
      overflow-y: scroll;
      // overflow-y:hidden;
      padding: 0 30px;
      background: #fff;
      padding-bottom: 207px !important;
    }

    #box2 {
      width: 100%;
      padding-bottom: 400px !important;
      .daan {
        > div {
          padding: 20px;
          margin-top: 40px;
          background: #fff;
          box-shadow: 0px 0px 10px #cfcfcf;

          // margin-top: 40px;
        }

        .youanswer {
          display: flex;
          align-items: center;
          span {
            color: #4280f4;
            // font-size: 36px;
          }
        }
          .reference1 {
            display: flex;
            div {
              color: #25c17c;
              // font-size: 36px;
            }
          }

        .resolve {
          color: #000;
          // font-size: 36px;

          margin-bottom: 30px;
        }
      }
    }
  }
  // 判断
  .judge {
    .analysis {
      .analysisDa {
        .nide {
          .youanswer {
            color: #4280f4;
          }
        }
        .daan {
          .refrance {
            color: #25c17c;
          }
        }
      }
    }
  }
  // 问答题+听力
  .Answer,
  .Hearing {
    > div {
      .audioTitle {
        height: 400px;
        overflow: auto;
        padding-bottom: 207px !important;
      }
    }

    #box2 {
      // background: #fff;
      // width: 100%;
      // height: 600px;
      // overflow: auto;
      // position: fixed;
      // bottom: 100px;
      padding-bottom: 250px !important;

      .nide-answer {
        margin-top: 40px;
        background: #fff;
        box-shadow: 0px 0px 10px #cfcfcf;
        padding: 40px;
        border-radius: 10px;
        /deep/table {
              width: 610px !important;
            }
        .youanswer {
          color: #4280f4;

          line-height: 60px;
        }
        .daan {
          margin-top: 20px;

          .refrance {
            color: #25c17c;
          }
        }
        .fontcolor {
          //
          font-size: 28px;
        }
      }

      .jiexi {
        max-width: 100%;

        div {
          &:nth-child(2) {
            text-indent: 1em;
          }
        }
      }
    }
  }
  #box1 {
    width: 100%;
    position: fixed;
    bottom: 0px;
    background: #fff;
    box-shadow: 0px 0px 10px #cfcfcf;
    .touchmove {
      text-align: center;
      margin-bottom: 20px;
      // background: red;
      button {
        width: 300px !important;
        height: 58px;
        line-height: 40px;
        overflow: hidden;
        border-radius: 0px 0px 45px 45px;
        font-size: 20px !important;

        padding: 0 30px;
        border: none;
        color: #999;
        background: #f7f7f7;
      }
    }
  }
  #box2 {
    overflow: auto;
  }
  /deep/ img {
    width: 100%;
    height: 400px;
    padding: 20px;
  }
  // .audioTitle {
  //   padding: 0 30px;
  // }
}
</style>
